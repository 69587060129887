// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from '@src/service/axios';

import { toast } from 'react-toastify';

export const getLabel = createAsyncThunk('labelSliceData/getLabel', async () => {
    try {
        let response = await axios.get('/Label', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getLabelOption = createAsyncThunk('labelSliceData/getLabelOption', async () => {
    try {
        let response = await axios.get('/Label?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getLabelStatus = createAsyncThunk('labelSliceData/getLabelStatus', async () => {
    try {
        let response = await axios.get('/Label?status=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteLabel = createAsyncThunk('labelSliceData/deleteLabel', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Label/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Label deleted successfully.');
        await dispatch(getLabel());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createLabel = createAsyncThunk('labelSliceData/createLabel', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Label', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        data?.showToast != false && toast.success(response?.data?.message || 'Label added succesfully.');
        await dispatch(getLabel());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLabel = createAsyncThunk('labelSliceData/updateLabel', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Label/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Label updated succesfully.');
        await dispatch(getLabel());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLabelStatus = createAsyncThunk('labelSliceData/updateLabelStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/Label/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Updated Succesfully');
        await dispatch(getLabel());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const labelSlice = createSlice({
    name: 'labelSliceData',
    initialState: {
        label: [],
        labelStatus: [],
        option: []
    },
    extraReducers: (builder) => {
        builder.addCase(getLabel.fulfilled, (state, action) => {
            state.label = action?.payload;
            return;
        });
        builder.addCase(getLabelStatus.fulfilled, (state, action) => {
            state.labelStatus = action?.payload;
            return;
        });
        builder.addCase(getLabelOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
    }
});

export default labelSlice.reducer;
