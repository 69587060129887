// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import queryString from 'query-string';

// ** API Imports
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

//** Location Cascade Api
export const getEvents = createAsyncThunk('remainderCalendarSlice/getEvents', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/Calendar?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getEventsById = createAsyncThunk('remainderCalendarSlice/getEventsId', async (id, includes = 0) => {
    try {
        let response = await axios.get('/Calendar/' + id, {
            headers: {
                'Content-Type': 'application/json',
                includes: includes
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteEvents = createAsyncThunk('remainderCalendarSlice/deleteEvents', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/Calendar/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Events deleted successfully.');
        await dispatch(getEvents());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createEvents = createAsyncThunk('remainderCalendarSlice/createEvents', async (data, { dispatch, getState }) => {
    try {
        let response = await axios.post('/Calendar', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Events added succesfully.');
        await dispatch(getEvents(getState().remainderCalendarSlice.selectedCalendars));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateEvents = createAsyncThunk('remainderCalendarSlice/updateEvents', async (data, { dispatch, getState }) => {
    try {
        let response = await axios.put('/Calendar/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Events updated succesfully.');
        await dispatch(getState().remainderCalendarSlice.selectedCalendars);
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const dragupdateEvents = createAsyncThunk('remainderCalendarSlice/dragupdateEvents', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/Calendar/update-event/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Events updated succesfully.');
        // await dispatch(getEvents());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateFilter = createAsyncThunk('remainderCalendarSlice/updateFilter', async (filter, { dispatch, getState }) => {
    const filters = ['Personal', 'Business', 'Family', 'Holiday', 'ETC'];

    let updatedCalendars;

    if (getState().remainderCalendarSlice.selectedCalendars.includes(filter)) {
        updatedCalendars = getState().remainderCalendarSlice.selectedCalendars.filter((i) => i !== filter);
    } else {
        updatedCalendars = [...getState().remainderCalendarSlice.selectedCalendars, filter];
    }

    const numberedFilters = updatedCalendars.map((filter) => filters.indexOf(filter) + 1);

    await dispatch(getEventByFilter(numberedFilters));

    return filter;
});

export const getEventByFilter = createAsyncThunk('remainderCalendarSlice/getEventByFilter', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/Calendar/filters?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateAllFilters = createAsyncThunk('remainderCalendarSlice/updateAllFilters', async (value, { dispatch }) => {
    if (value === true) {
        await dispatch(getEventByFilter(['Personal', 'Business', 'Family', 'Holiday', 'ETC']));
    } else {
        await dispatch(getEventByFilter([]));
    }
    return value;
});
export const remainderCalendarSlice = createSlice({
    name: 'remainderCalendarSlice',
    initialState: {
        events: [],
        selectedEvent: {},
        selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
    },
    reducers: {
        removeEvent: (state, action) => {
            state.selectedEvent = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEvents.fulfilled, (state, action) => {
                state.events = action?.payload;
                return;
            })
            // Example of how your Redux reducer might handle updateFilter
            .addCase(updateFilter.fulfilled, (state, action) => {
                const filter = action.payload;

                // Toggle the filter in the selectedCalendars array
                if (state.selectedCalendars.includes(filter)) {
                    state.selectedCalendars = state.selectedCalendars.filter((i) => i !== filter);
                } else {
                    state.selectedCalendars.push(filter);
                }
            })

            .addCase(updateAllFilters.fulfilled, (state, action) => {
                const value = action.payload;
                let selected = [];
                if (value === true) {
                    selected = ['Personal', 'Business', 'Family', 'Holiday', 'ETC'];
                } else {
                    selected = [];
                }
                state.selectedCalendars = selected;
            });
        builder.addCase(getEventsById.fulfilled, (state, action) => {
            state.selectedEvent = action?.payload;
            return;
        });
    }
});
export const { removeEvent } = remainderCalendarSlice.actions;

export default remainderCalendarSlice.reducer;
