//** Reporting Api

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

// ** Query String Imports
import queryString from 'query-string';

export const getReporting = createAsyncThunk('reportingSliceData/getReporting', async () => {
    try {
        let response = await axios.get('/reporting', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getReportingBbyId = createAsyncThunk('reportingSliceData/getReportingBbyId', async (Id) => {
    try {
        let response = await axios.get('/reporting/report-by-id/' + Id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFinalReporting = createAsyncThunk('reportingSliceData/getFinalReporting', async (data) => {
    try {
        let response = await axios.post('/reporting/report', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getNewReporting = createAsyncThunk('reportingSliceData/getNewReporting', async (data) => {
    try {
        let response = await axios.post('/Reporting/group-by-report', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getReportingWiseProjectOption = createAsyncThunk('projectData/getReportingWiseProjectOption', async (data) => {
    try {
        const query = data && Object.keys(data).length > 0 ? `?${queryString.stringify(data)}` : '';
        let response = await axios.get('/reporting/employee-wise' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getReportingLastDate = createAsyncThunk('reportingSliceData/getReportingLastDate', async (data) => {
    try {
        const query = data && Object.keys(data).length > 0 ? `?${queryString.stringify(data)}` : '';

        let response = await axios.get('/reporting/last-date' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDraftReporting = createAsyncThunk('reportingSliceData/getDraftReporting', async (data) => {
    try {
        const query = data && Object.keys(data).length > 0 ? `?${queryString.stringify(data)}` : '';
        let response = await axios.get('/reporting/draft' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteReporting = createAsyncThunk('reportingSliceData/deleteReporting', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/reporting/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Reporting deleted successfully.');
        // await dispatch(getReportingLastDate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createReporting = createAsyncThunk('reportingSliceData/createReporting', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/reporting', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Reporting added succesfully.');
        // await dispatch(getReportingLastDate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateReporting = createAsyncThunk('reportingSliceData/updateReporting', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/reporting/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (data?.data?.isToast) toast.success(response?.data?.message || 'Reporting updated succesfully.');
        // await dispatch(getReportingLastDate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const finalReporting = createAsyncThunk('reportingSliceData/finalReporting', async (data, { dispatch }) => {
    try {
        const response = await axios.post(`/reporting/final-reporting${data?.id ? `?id=${data.id}` : ''}`, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Reporting updated succesfully.');
        await dispatch(getReportingLastDate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateReportingStatus = createAsyncThunk('reportingSliceData/updateReportingStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/reporting/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        dispatch(getReporting());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const importReporting = createAsyncThunk('reportingSliceData/importReporting', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/Reporting/import', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Reporting import succesfully.');
        // await dispatch(getReportingLastDate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const moduleImportReporting = createAsyncThunk('reportingSliceData/moduleImportReporting', async (data) => {
    try {
        let response = await axios.post(`/ProjectModule/import/${data.id}`, data.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Module import succesfully.');
        // await dispatch(getReportingLastDate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getSendMail = createAsyncThunk('reportingSliceData/getSendMail', async (data) => {
    try {
        const params = data.date ? { Date: data.date } : {};
        let response = await axios.get(`/reporting/status-report-by-date`, {
            params,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Daily Status Report has been sent successfully.');
        // await dispatch(getReportingLastDate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const reportingSlice = createSlice({
    name: 'reportingSliceData',
    initialState: {
        data: [],
        lastDateData: [],
        projectOptions: [],
        draftData: [],
        finalReportingData: [],
        reportingData: {},
        newReportData: []
    },
    extraReducers: (builder) => {
        builder.addCase(getReporting.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getFinalReporting.fulfilled, (state, action) => {
            state.finalReportingData = action?.payload;
            return;
        });
        builder.addCase(getReportingBbyId.fulfilled, (state, action) => {
            state.reportingData = action?.payload;
            return;
        });
        builder.addCase(getReportingLastDate.fulfilled, (state, action) => {
            state.lastDateData = action?.payload;
            return;
        });
        builder.addCase(getReportingWiseProjectOption.fulfilled, (state, action) => {
            state.projectOptions = action?.payload;
            return;
        });
        builder.addCase(getDraftReporting.fulfilled, (state, action) => {
            state.draftData = action?.payload;
            return;
        });
        builder.addCase(getNewReporting.fulfilled, (state, action) => {
            state.newReportData = action?.payload;
            return;
        });
    }
});

export default reportingSlice.reducer;
